import { useRouter } from 'next/router';
import clsx from 'clsx';
import Link from 'next/link';

import { Anchor, IconButton } from '@fu/ui';
import { Close } from '@fu/icons';

import { MenuQueryResults } from './types';
import { Locale } from '@/types';

export type Screen = 'menu' | 'search' | 'results';

export type MenuProps = Omit<MenuQueryResults, '_type'> & {
  onClose?: () => void;
};

export default function Menu({ featured, linkGroups, onClose }: MenuProps) {
  const router = useRouter();

  return (
    <nav
      className={clsx(
        'fixed top-0 right-0',
        'p-8 md:p-12 lg:p-16 xl:p-18',
        'h-full w-full max-w-[640px]',
        'bg-neutral-0',
        'flex flex-col justify-center',
        'overflow-y-auto'
      )}
      id="site-nav"
      aria-labelledby="site-nav-button"
    >
      <ul className="mb-16 flex flex-col gap-4">
        {featured.map((link) => (
          <li key={link._key}>
            <Link
              href={
                (link.internal
                  ? link.internal.pathname || `/${link.internal.slug.current}`
                  : link.external) ?? ''
              }
              legacyBehavior
              passHref
            >
              <Anchor size="lg">
                {(link.title ?? {})[router.locale as Locale]}
              </Anchor>
            </Link>
          </li>
        ))}
      </ul>

      <div className="flex w-full flex-col gap-10 sm:flex-row">
        {linkGroups.map((group) => (
          <div key={group._key} className="flex-1">
            <h3 className={clsx('mb-6 font-matter text-md font-semibold')}>
              {group.title[router.locale as Locale]}
            </h3>

            <ul className="flex flex-col gap-4">
              {group.links.map((link) => (
                <li key={link._key}>
                  <Link
                    href={
                      (link.internal
                        ? link.internal.pathname ||
                          `/${link.internal.slug.current}`
                        : link.external) ?? ''
                    }
                    legacyBehavior
                    passHref
                  >
                    <Anchor size="sm" iconRight="arrow-right">
                      {(link.title ?? {})[router.locale as Locale]}
                    </Anchor>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <IconButton
        onClick={onClose}
        size="xl"
        container={false}
        className="fixed top-4 right-4 md:top-6 md:right-8"
        aria-label="Close site navigation"
      >
        <Close />
      </IconButton>
    </nav>
  );
}
