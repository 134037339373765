import { Suspense, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { LogoJsonLd } from 'next-seo';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';

import { Drawer, Anchor, Logo, IconButton } from '@fu/ui';
import { Menu as MenuIcon } from '@fu/icons';

import { HOST } from '@/lib/host';

import Menu from '@/components/menu';
import type { MenuQueryResults } from '@/components/menu';

const Autocomplete = dynamic(
  () => import('@/components/algolia/autocomplete'),
  {
    suspense: true,
  }
);

type HeaderProps = MenuQueryResults;

export default function Header({ featured, linkGroups }: HeaderProps) {
  const t = useTranslations();
  const [showNav, setShowNav] = useState<boolean>(false);

  return (
    <header
      className={clsx(
        // 'sticky top-0 z-20',
        'py-4 px-4 sm:pt-6 md:px-8',
        'flex flex-wrap gap-4',
        ' bg-neutral-0'
      )}
    >
      <div className="flex items-center sm:order-1">
        <LogoJsonLd logo={`${HOST}/fu-logo.png`} url={`${HOST}`} />
        <Link
          href="/"
          title={t('a11y.logoLabel')}
          aria-label={t('a11y.logoLabel')}
        >
          <Logo text="horizontal" className="h-10" />
        </Link>

        <nav className="mt-1 hidden px-6 xl:block">
          <ul className="flex items-center gap-6">
            {featured.map((link) => (
              <li key={link._key} className="text-center">
                <Link
                  href={
                    (link.internal
                      ? link.internal.pathname ||
                        `/${link.internal.slug.current}`
                      : link.external) ?? ''
                  }
                  legacyBehavior
                  passHref
                >
                  <Anchor size="sm" className="no-underline">
                    {link.title?.nb}
                  </Anchor>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <div className="ml-auto flex h-full items-center sm:order-3 sm:ml-0 md:ml-6">
        <IconButton
          id="site-nav-button"
          // {...openButtonProps}
          onClick={() => setShowNav(true)}
          size="xl"
          aria-haspopup="true"
          aria-controls="site-nav"
          aria-expanded={showNav}
          container={false}
          aria-label={t('a11y.navButtonLabel')}
          title={t('a11y.navButtonLabel')}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key === 'ArrowDown') {
              setShowNav(true);
            }
          }}
        >
          <MenuIcon className="h-8 w-8 lg:h-10 lg:w-10" />
        </IconButton>

        <Drawer isOpen={showNav} onClose={() => setShowNav(false)}>
          <Menu featured={featured} linkGroups={linkGroups} />
        </Drawer>
      </div>

      <div className="just flex w-full flex-col items-stretch justify-center sm:order-2 sm:ml-auto sm:w-auto">
        <Suspense fallback={null}>
          <Autocomplete />
        </Suspense>
      </div>
    </header>
  );
}
